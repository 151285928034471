import React, { useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import { Tooltip } from "react-tippy";
import Confetti from "react-dom-confetti";
import { Layout, Input, Radio, Check, Loader } from "../components";
import { test } from "../utils/test";

const config = {
  angle: 90,
  spread: 100,
  startVelocity: 60,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 2000,
  stagger: 0,
  width: "7px",
  height: "10px",
  colors: [
    "#E68F17",
    "#FAB005",
    "#FA5252",
    "#E64980",
    "#BE4BDB",
    "#0B7285",
    "#15AABF",
    "#EE1233",
    "#40C057",
  ],
};

const Tip = (props) => (
  <Tooltip
    {...props}
    animation={"scale"}
    distance={10}
    position="top"
    transitionFlip={false}
    animateFill={false}
    style={{ fontSize: 0, marginLeft: 5 }}
  >
    <span className="tooltip-ico"></span>
  </Tooltip>
);

const Test = ({ pageContext: { slug } }) => {
  const { register, errors, handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);

  const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  const checkPoints = (strong, weak) => {
    const getPoints = (array, subArray) =>
      array.filter((item) => subArray.includes(item)).length;
    const strongTowarzyskiSangwinik = [
      "Żywy",
      "Wesoły",
      "Lgnie do ludzi",
      "Urzekający",
      "Pokrzepiający",
      "Pełen werwy",
      "Promotor",
      "Spontaniczny",
      "Optymista",
      "Dowcipny",
      "Czarujący",
      "Pogodny",
      "Inspirator",
      "Wylewny",
      "Towarzyski",
      "Rozmowny",
      "Energiczny",
      "Ujmujący",
      "Popularny",
      "Żywotny",
    ];
    const strongEnergicznyCholeryk = [
      "Przedsiębiorczy",
      "Przekonywający",
      "O silnej woli",
      "Rywalizujący",
      "Zaradny",
      "Samodzielny",
      "Nastawiony na sukces",
      "Pewny",
      "Szczery",
      "Autorytatywny",
      "Odważny",
      "Pewny siebie",
      "Niezależny",
      "Stanowczy",
      "Działacz",
      "Nieustępliwy",
      "Przywódca",
      "Szef",
      "Pracowity",
      "Śmiały",
    ];
    const strongPerfekcyjnyMelancholik = [
      "Analityczny",
      "Wytrwały",
      "Poświęcający się",
      "Delikatny",
      "Pełen szacunku",
      "Wrażliwy",
      "Planujący ",
      "Zorganizowany",
      "Uporządkowany",
      "Wierny",
      "Drobiazgowy",
      "Kulturalny",
      "Idealista",
      "Głęboki",
      "Meloman",
      "Grzeczny",
      "Lojalny",
      "Organizator",
      "Perfekcjonista",
      "Taktowny",
    ];
    const strongSpokojnyFlegmatyk = [
      "Elastyczny",
      "Spokojny",
      "Ugodowy",
      "Opanowany",
      "Powściągliwy",
      "Niewymagający",
      "Cierpliwy",
      "Nieśmiały",
      "Uprzejmy",
      "Życzliwy",
      "Dyplomatyczny",
      "Stały",
      "Nieszkodliwy",
      "Cięty",
      "Rozjemca",
      "Tolerancyjny",
      "Słuchacz",
      "Zadowolony",
      "Sympatyczny",
      "Zrównoważony",
    ];

    const weakTowarzyskiSangwinik = [
      "Buńczuczny",
      "Niezdyscyplinowany",
      "Powtarzający się",
      "Zapominalski",
      "Wtrącający się",
      "Niestały",
      "Chaotyczny",
      "Na wszystko pozwala",
      "Złośnik",
      "Naiwny",
      "Lizus",
      "Gadatliwy",
      "Niezorganizowany",
      "Niestały",
      "Bałaganiarz",
      "Próżny",
      "Donośny",
      "Roztargniony",
      "Niespokojny",
      "Zmienny",
    ];
    const weakEnergicznyCholeryk = [
      "Apodyktyczny",
      "Beznamiętny",
      "Oporny",
      "Zuchwały",
      "Niecierpliwy",
      "Skryty",
      "Twardogłowy",
      "Megaloman",
      "Dyskutant",
      "Denerwujący",
      "Pracoholik",
      "Nietaktowny",
      "Dominujący",
      "Nietolerancyjny",
      "Manipulant",
      "Uparty",
      "Pyszałek",
      "Zapalaczywy",
      "Pochopny",
      "Przebiegły",
    ];
    const weakPerfekcyjnyMelancholik = [
      "Wstydliwy",
      "Pamiętliwy",
      "Obraźliwy",
      "Kapryśny",
      "Niepewny",
      "Niepopularny",
      "Wredny",
      "Pesymista",
      "Wyobcowany",
      "Nastawiony negatywnie",
      "Izolujący się",
      "Przewrażliwiony",
      "Przygnębiony",
      "Zamknięty w sobie",
      "Nastrojowy",
      "Nieufny",
      "Samotnik",
      "Podejrzliwy",
      "Mściwy",
      "Krytykant",
    ];
    const weakSpokojnyFlegmatyk = [
      "Chłodny",
      "Apatyczny",
      "Małomówny",
      "Lękliwy",
      "Niezdecydowany",
      "Odludek",
      "Ociągający się",
      "Bezbarwny",
      "Nie mający celu",
      "Nonszalancki",
      "Zatroskany",
      "Bojaźliwy",
      "Sceptyk",
      "Obojętny",
      "Mamrot",
      "Powolny",
      "Leniwy",
      "Niemrawy",
      "Dystansujący się",
      "Ugodowiec",
    ];
    const points = {
      strongTowarzyskiSangwinikPoints: getPoints(
        strong,
        strongTowarzyskiSangwinik
      ),
      strongEnergicznyCholerykPoints: getPoints(
        strong,
        strongEnergicznyCholeryk
      ),
      strongPerfekcyjnyMelancholikPoints: getPoints(
        strong,
        strongPerfekcyjnyMelancholik
      ),
      strongSpokojnyFlegmatykPoints: getPoints(strong, strongSpokojnyFlegmatyk),
      weakTowarzyskiSangwinikPoints: getPoints(weak, weakTowarzyskiSangwinik),
      weakEnergicznyCholerykPoints: getPoints(weak, weakEnergicznyCholeryk),
      weakPerfekcyjnyMelancholikPoints: getPoints(
        weak,
        weakPerfekcyjnyMelancholik
      ),
      weakSpokojnyFlegmatykPoints: getPoints(weak, weakSpokojnyFlegmatyk),
    };

    return points;
  };

  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    delete data.agree;
    delete data.agree_1;
    const points = checkPoints(data.strong, data.weak);

    triggerConfetti(true);

    let formData = new FormData();
    formData.set("firstname", data.name);
    formData.set("lastname", data.lastname);
    formData.set("email", data.email);
    formData.set("company", data.company);
    formData.set("recruiter", data.recruiter);
    formData.set("strong", data.strong);
    formData.set("weak", data.weak);
    formData.set("points", JSON.stringify(points));

    axios({
      method: "post",
      url: "https://wp.adream.pl/wp-json/contact-form-7/v1/contact-forms/1116/feedback",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(true);
          axios
            .post("https://adream-mailing.herokuapp.com/recrutacion-test", {
              ...data,
              points,
            })
            .then(() => {
              setLoading(false);
              triggerConfetti(false);
              e.target.reset();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
          navigate("/test-osobowosci-potwierdzenie");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout
      seo={{
        title: "Test osobowości",
        href: slug,
        lang: "pl",
      }}
    >
      <div className="test__container test">
        <section className="test_section_1">
          <div className="text-center">
            <h1>Test osobowości</h1>
          </div>
        </section>
        <section className="test_section_2">
          <p>
            W każdym z poziomych rzędów składających się z czterech słów,
            zaznacz pole wyboru przed jednym określeniem, które jest najczęściej
            stosowane w odniesieniu do Ciebie. Wypełnij w ten sposób wszystkie
            czterdzieści rzędów; upewnij się, czy każdy numer został oznaczony.
            Jeżeli bedzie pasowała wiecej niż jedna odpowiedź, zaznacz tą, która
            najmocniej Cię charakteryzuje, jeżeli nie będzie pasowała żadna
            odpowiedź, zaznacz tą, która pomimo wszystko jest najbliższa prawdy.
            Wszystkie definicje osobowości zostały opracowane na podstawie
            Personality Patterns Lany Bateman. <br />
            <br />{" "}
            <strong>
              Po wykonaniu testu proszę zgłosić się do osoby przeprowadzącej
              rekrutację.
            </strong>
          </p>
        </section>
        <section className="test_form-section">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="checkbox"
              name="bot_field"
              ref={register}
              className="bot-field"
            />
            <div className="row">
              <div className="col-md-4">
                <Input
                  type="text"
                  name="name"
                  label="Imię"
                  register={register}
                  validation={{
                    required: "Wymagane",
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <Input
                  type="text"
                  name="lastname"
                  label="Nazwisko"
                  register={register}
                  validation={{
                    required: "Wymagane",
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <Input
                  type="email"
                  name="email"
                  label="Adres e-mail"
                  register={register}
                  validation={{
                    required: "Wymagane",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                      message: "Błędny adres e-mail",
                    },
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="inp-group audytSelect">
                  <select ref={register} name="company">
                    <option value="Rekrutujesz do:">Rekrutujesz do:</option>
                    <option value="ADream">ADream</option>
                    <option value="When">When</option>
                    <option value="Zensite">Zensite</option>
                    <option value="StudioLeon">StudioLeon</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  name="recruiter"
                  label="Osoba rekrutująca:"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
            <div className="tooltip-demo">
              <span>
                Po najechaniu na pytajnik pojawi się wyjaśnienie cechy
              </span>
              <Tip disabled />
            </div>

            <article className="test_form-section__part icon">
              <div className="test_form-section__part-header">
                <h2>Mocne strony</h2>
              </div>
              {test.advantages.map(({ row }, index) => (
                <div
                  key={index}
                  className={`test_form-section__part-row ${
                    errors[`strong[${index}]`] ? "error" : ""
                  }`}
                >
                  {row.map(({ value, tooltip }) => (
                    <Radio
                      name={`strong[${index}]`}
                      key={value}
                      id={`${capitalize(value)}${index}`}
                      value={capitalize(value)}
                      label={
                        <>
                          {capitalize(value)}
                          <Tip title={tooltip} />
                        </>
                      }
                      validation={{ required: "Wymagane" }}
                      register={register}
                      errors={null}
                    />
                  ))}
                  {errors[`strong[${index}]`] && (
                    <p className="test-error">
                      {errors[`strong[${index}]`].message}
                    </p>
                  )}
                </div>
              ))}
            </article>
            <article className="test_form-section__part">
              <div className="test_form-section__part-header">
                <h2>Słabe strony</h2>
              </div>
              {test.disadvantages.map(({ row }, index) => (
                <div
                  key={index}
                  className={`test_form-section__part-row ${
                    errors[`weak[${index}]`] ? "error" : ""
                  }`}
                >
                  {row.map(({ value, tooltip }) => (
                    <Radio
                      name={`weak[${index}]`}
                      id={`${capitalize(value)}${index}`}
                      value={capitalize(value)}
                      key={value}
                      validation={{ required: "Wymagane" }}
                      label={
                        <>
                          {capitalize(value)}
                          <Tip title={tooltip} />
                        </>
                      }
                      register={register}
                      errors={null}
                    />
                  ))}
                  {errors[`weak[${index}]`] && (
                    <p className="test-error">
                      {errors[`weak[${index}]`].message}
                    </p>
                  )}
                </div>
              ))}
            </article>
            <div className="test_form-section__summary">
              <div className="shape-icon" />
              <Check
                name="agree"
                size="sm"
                validation={{ required: "Wymagane" }}
                label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez Adream sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 REGON 123163480 (Administrator danych) w celu udzielenia mi informacji o wynikach testu osobowości. Podanie danych jest dobrowolne i umożliwia uzyskanie informacji o wynikach testu."
                register={register}
                errors={errors}
              />
              <Check
                name="agree2"
                size="sm"
                label="Wyrażam zgodę na udostępnienie moich danych osobowych przez Adream sp. z o.o., z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 jako administratora moich danych osobowych, podmiotowi: When Sp. z o. o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000683438 (kolejnemu Administratorowi danych), w celu udzielenia mi dodatkowych informacji handlowych z zakresu oferty When sp. z o.o. Podanie danych jest dobrowolne i umożliwia uzyskanie informacji handlowej.*"
                register={register}
                errors={errors}
              />
              <div className="summary-button">
                <button
                  type="submit"
                  className="btn btn-default"
                  disabled={formState.isSubmitting}
                >
                  Wyślij
                  <Confetti active={showConfetti} config={config} />
                </button>
              </div>
            </div>
          </form>
          <div className="additional-info">
            <p className="clauzule">
              <strong>Klauzula informacyjna dla kandydatów do pracy:</strong>{" "}
              Administratorem Danych Osobowych jest Adream sp. z o.o. z siedzibą
              w Krakowie, ul. Chopina 18/3a, KRS: 0000517132. Wgląd do wyników
              testu ma jedynie osoba przeprowadzająca rekrutację. Dane podane w
              formularzu posłużą w celach przeprowadzenia procesu rekrutacji i
              selekcji. Wyniki będą przechowywane przez 3 miesiące od daty
              wykonania testu (w przypadku kandydatów, którzy nie zostaną
              zatrudnieni) lub 3 miesiące od daty ustania zatrudnienia (w
              przypadku kandydatów zatrudnionych przez Agencję).
            </p>
          </div>
        </section>
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
};

export default Test;
